<template>
  <div class="cadastro-usuario">
    <v-container fluid>
      <h1 class="titulo">Lista de acessos</h1>

      <div class="box-table">
        <v-skeleton-loader
            :loading="loadingTable"
            :transition="'fade-transition'"
            height="500"
            type="table">
          <v-data-table
            :headers="headers"
            :search="search"
            :items="listaItens"
            width="300px"
            flat
            hide-default-footer
            :loading="loadingPag"
            :items-per-page.sync="itemsPerPage"> 

            <template v-slot:top>
              <v-row class="justify-content-end">
              <div class="col-2 pb-0 pl-0 d-flex" style="position:relative; left: -7px">
                  <v-menu :close-on-content-click="true" :nudge-right="35" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="computedDateFormatted"
                        label="Data"
                        prepend-icon="mdi-calendar"
                        v-on="on"
                        class="mt-0 pt-1 ml-4"
                        v-bind="attrs"
                        readonly/>
                    </template>
                    <v-date-picker v-model="selectedDate"/>
                  </v-menu>
              </div>
              <div class="col-2 pb-0 pt-0 pl-0 d-flex" style="position:relative; left: -7px">
                 <v-select :items="itensHours" v-model="horaDia" label="Hora" />
              </div>
              <div class="col-2 pb-0 pt-0 pl-0 d-flex" style="position:relative; left: -7px">
                 <v-select :items="itensMinutos" v-model="minutoDia" label="Minuto" />
              </div>
              <div class="col-2 pb-0 pt-0 pl-0 d-flex justify-start align-center">
                 <v-btn class="br-btn" :disabled="loadingPag" x-large color="primary" @click="buscarLogs(true)"> Buscar </v-btn>
              </div>
                <BrToolBar
                  @search="buscar"
                  :itemsSelect="itemsSelect"
                  :configFilter="{
                    listaSelect: listaItensSelectFilter,
                    getItens: getItens,
                    jsonData: false,
                    disableTextField: true
                  }"/>

              </v-row>
            </template>

            <template v-slot:footer>
              <div class="wrapper-footer">
                <div class="qtd-paginacao">
                  {{ pageStart }}
                  -
                  {{ pageStop }}
                  de
                  {{ totalItens }}
                </div>
                <v-pagination
                  v-model="page"
                  :length="numberOfPages"
                  circle
                  :disabled="disabled"
                  :total-visible="7"
                  class="box-table-paginacao"
                  v-if="numberOfPages> 1"></v-pagination>
              </div>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>

      <v-btn class="mx-2 fab-cadastrar-usuario" fab color="primary" @click="cadastrar">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </v-container>
    <!-- <InfoModal/> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// import { errorSnackbar } from '@/core/service/utils'
import listagemTables from '@/views/mixins/listagemTables'
import Events from '@/core/service/events'
import moment from 'moment'
moment.locale('pt-br')
const namespaceStore = 'acessos'

export default {
  name: 'AcessosLogs',
  components: {
    BrToolBar: () => import('./components/toolbar'),
    // InfoModal: () => import('./components/infoModal')
  },
  mixins: [listagemTables],
  data: () => ({
    page: 1,
    selectedDate: new Date().toISOString().substr(0, 10),
    dateFormat: new Date().toISOString().substr(0, 10),
    itensHours: Array.from({ length: 24 }, (v, k) => String(k).padStart(2, '0')),
    itensMinutos: Array.from({ length: 60 }, (v, k) => String(k).padStart(2, '0')),
    minutoDia: moment().format('MM'),
    horaDia: moment().format('HH'),
    headers: [
      { align: 'start', class: 'table-header', text: 'ID', value: 'id' },
      { align: 'start', class: 'table-header', text: 'Nome do usuário', value: 'user.name' },
      { align: 'start', class: 'table-header', text: 'ID do usuário', value: 'user.id' },
      { align: 'start', class: 'table-header', text: 'Email do usuário', value: 'user.email' },
      { align: 'start', class: 'table-header', text: 'URL de acesso', value: 'url' },
      { align: 'start', class: 'table-header', text: 'ID do resultado', value: 'idRequest' },
      { align: 'end', class: 'table-header', text: 'Data', value: 'date' },
      // { align: 'start', class: 'table-header', text: 'Status', value: 'status' },
      // { align: 'end', class: 'table-header', text: '', value: 'action', sortable: false },
    ]
  }),

  mounted () {
    const vm = this
    this.buscarLogs()

    Events.$on('toolbar::selectFilter', (e) => {
      this.loadingPag = true
      if (e.value) {
          vm.setObjFilter({ _addlFilter: { 'url_ilike':`${e.value}`} })
          vm.buscarLogs({ _addlFilter: { 'url_ilike':`${e.value}`} })
          return
      }
      vm.buscarLogs()
    })
  },
  watch: {
    selectedDate (val) {
      const vm = this
      vm.dateFormat = vm.formatDate(val)
      this.$nextTick(() => vm.buscarLogs(true))

    },
    page (e) {
      const vm = this
      this.setPage(e)
      this.$nextTick(() => vm.buscarLogs(e))
    }
  },
  computed: {
    ...mapGetters(namespaceStore, ['listaItens', 'totalItens', 'item']),
    computedDateFormatted () {
      let data = this.formatDate(this.selectedDate)
      return data
    },
    itemsSelect: () =>  [
      { text: 'Todos', value: '' },
      { text: 'Adicionados', value: 'add' },
      { text: 'Visualizações', value: 'view' },
      { text: 'Deletados', value: 'delete' },
      { text: 'Login', value: 'login' },
      { text: 'Saldos', value: 'balance' },
      { text: 'Vendas', value: 'sell' },
      { text: 'Compras', value: 'buy' },
    ],

    listaItensSelectFilter: () => ({
      nome: ''
    })
  },

  methods: {
    ...mapActions(namespaceStore, ['getItens', 'buscarItem', 'clearItens']),
    ...mapActions('filter', ['setObjFilter']),
    paginacao () {},
    init () {},
    buscarLogs (filtro = false) {
      let itens = { '_addlFilter': {} }
      const vm = this
      const start = moment(vm.selectedDate).startOf('days').format(`YYYY-MM-DD [${this.horaDia}]:[${this.minutoDia}]:ss`)
      const endMinute = +this.minutoDia + 10 >= 59 ? 59 : +this.minutoDia + 10 
      const end = moment(vm.selectedDate).endOf('days').format(`YYYY-MM-DD [${this.horaDia}]:[${endMinute}]:ss`)

      const objFilter = this.objFilter
      if (!filtro) return vm.getItens({
        ...objFilter,
        page: this.page,
        pageSize: this.$store.getters.ItensPaginacao,
        _addlFilter: { 'created_>=':`${start}`, 'created_<=': `${end}` } })
      .finally(() => {
        vm.loadingTable = false
        vm.loadingPag = false
      })
      
      itens['_addlFilter']['created_>='] = start
      itens['_addlFilter']['created_<='] = end

      if (itens && itens['_addlFilter']) itens['_addlFilter'] = Object.assign(itens['_addlFilter'], itens['_addlFilter'])
      if (objFilter && objFilter['_addlFilter']) itens['_addlFilter']['url_ilike'] = `%${objFilter['_addlFilter']['url_ilike']}%`
      
      
      this.loadingPag = true      
      this.getItens({
        page: this.page,
        pageSize: this.$store.getters.ItensPaginacao,
        ...itens
      }).finally(() => {
        vm.loadingPag = false
      })
    },
    formatDate (date) {
       if (!date) return null
      return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
    },
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/table';
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {
    // border-top: 5px solid $colorPrimary;
    // border-top-left-radius: 0 !important;
    // border-top-right-radius: 0 !important;

    .v-data-table-header-mobile {
      display: none;
    }

    .table-btn {
      font-weight: normal;
    }
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }
</style>