var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cadastro-usuario"},[_c('v-container',{attrs:{"fluid":""}},[_c('h1',{staticClass:"titulo"},[_vm._v("Lista de acessos")]),_c('div',{staticClass:"box-table"},[_c('v-skeleton-loader',{attrs:{"loading":_vm.loadingTable,"transition":'fade-transition',"height":"500","type":"table"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.listaItens,"width":"300px","flat":"","hide-default-footer":"","loading":_vm.loadingPag,"items-per-page":_vm.itemsPerPage},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"justify-content-end"},[_c('div',{staticClass:"col-2 pb-0 pl-0 d-flex",staticStyle:{"position":"relative","left":"-7px"}},[_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-right":35,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-0 pt-1 ml-4",attrs:{"label":"Data","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.computedDateFormatted),callback:function ($$v) {_vm.computedDateFormatted=$$v},expression:"computedDateFormatted"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1)],1),_c('div',{staticClass:"col-2 pb-0 pt-0 pl-0 d-flex",staticStyle:{"position":"relative","left":"-7px"}},[_c('v-select',{attrs:{"items":_vm.itensHours,"label":"Hora"},model:{value:(_vm.horaDia),callback:function ($$v) {_vm.horaDia=$$v},expression:"horaDia"}})],1),_c('div',{staticClass:"col-2 pb-0 pt-0 pl-0 d-flex",staticStyle:{"position":"relative","left":"-7px"}},[_c('v-select',{attrs:{"items":_vm.itensMinutos,"label":"Minuto"},model:{value:(_vm.minutoDia),callback:function ($$v) {_vm.minutoDia=$$v},expression:"minutoDia"}})],1),_c('div',{staticClass:"col-2 pb-0 pt-0 pl-0 d-flex justify-start align-center"},[_c('v-btn',{staticClass:"br-btn",attrs:{"disabled":_vm.loadingPag,"x-large":"","color":"primary"},on:{"click":function($event){return _vm.buscarLogs(true)}}},[_vm._v(" Buscar ")])],1),_c('BrToolBar',{attrs:{"itemsSelect":_vm.itemsSelect,"configFilter":{
                  listaSelect: _vm.listaItensSelectFilter,
                  getItens: _vm.getItens,
                  jsonData: false,
                  disableTextField: true
                }},on:{"search":_vm.buscar}})],1)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"wrapper-footer"},[_c('div',{staticClass:"qtd-paginacao"},[_vm._v(" "+_vm._s(_vm.pageStart)+" - "+_vm._s(_vm.pageStop)+" de "+_vm._s(_vm.totalItens)+" ")]),(_vm.numberOfPages> 1)?_c('v-pagination',{staticClass:"box-table-paginacao",attrs:{"length":_vm.numberOfPages,"circle":"","disabled":_vm.disabled,"total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)]},proxy:true}])})],1)],1),_c('v-btn',{staticClass:"mx-2 fab-cadastrar-usuario",attrs:{"fab":"","color":"primary"},on:{"click":_vm.cadastrar}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }